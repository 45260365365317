body {
  color: white;
  background: black;
  font-family: 'Raleway', sans-serif;
  height: 100%;
}

h1 {
  color: yellow;
  font-size: 44px;
}



.title-link a  {
  cursor: pointer;
  color:black;
  text-decoration: none;
}

.title-link {
  color: black;
}

a.title-link:hover, a.title-link:visited, a.title-link:link, a.title-link:active
{   
    color:black;
    text-decoration: none;
}


a:hover, a:visited, a:link, a:active
{   
    color: white;
    text-decoration: none;
}


.container {
  margin: auto;
  width: 600px;
}

.page {
 
  margin: auto;
  margin-top: 100px;
  margin-bottom: 400px;
  position: relative;
  min-height: 50vh;
  z-index: -100;
 
  
}

.terms {
  padding: 30px;
}

.center {
  margin: auto;
  margin-top: 20%;
  text-align: center;
}
.main {
  height: 1000px;
  width: 100%;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/megaphone-71290.appspot.com/o/public%2Fbroadcast.jpg?alt=media&token=3c4b1015-c368-4e75-8758-2077ea267d38");
  background-size: cover; 
 
}

.main-text {
  width: 40%;
  text-align: left;
  padding: 100px;
}

.userpage {
  color: white;
  background: rgb(33, 33, 33);
  margin: auto;
  margin-top: 10%;
  text-align: center;
  max-width: 400px;
  padding: 20px;

  
}

.avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.product-shot {
  width: 500px;
  
}

.form-input {
  font-size: 18px;
  padding: 8px;
  width: 300px;
}

.form-input:focus {
  outline: none;
}

.spaced {
  margin-bottom: 25px;
}

.megaphone-button {
  cursor: pointer;
  color: black;
  background: yellow;
  border: none;
  padding: 12px;
  font-size: 16px;
  width: 400px;
  border-radius: 4px;
  margin: auto;
  text-align: center;
}

.sms-link {
  color: black;
}


.sms-link:link {
  color: black;
}

.follow-button {
  cursor: pointer;
  color: black;
  background: yellow;
  border: none;
  padding: 12px;
  font-size: 20px;
  width: 200px;
  border-radius: 4px;
}

.follow-button:focus {
  outline: none;
}

.get-button {
  cursor: pointer;
  font-size: 18px;
  padding: 15px;
  background: yellow;
  border-radius: 6px;
}

.get-button:focus {
  outline: none;
}

.menu {
  color: black;
  padding: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 20px;
  background: white;
}

.footer {
  color: white;
  padding: 40px;
  background: rgb(39, 48, 58);

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* negative value of footer height */
  height: 100px;
  clear: both;
 
  
}


/* Loading spinner */
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}


/* Ripple */

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
